import Vue from 'vue';
const mixins = {
  install(Vue) {
    Vue.mixin({
      computed: {
        urlQuery() {
          const fullPath = this.$route.fullPath.split('?')
          if(fullPath.length > 1) {
            return fullPath[1]
          }
          return ''
        }
      }
    })
  }
}
Vue.use(mixins);