<template>
  <div class="summary">
    <div class="header" v-if="success">
      <inline-svg :src="require('@/assets/imgs/star.svg')"></inline-svg>
      <span class="success-title title-fam">{{$t('success-title')}}</span>
      <inline-svg :src="require('@/assets/imgs/star-right.svg')"></inline-svg>
    </div>
    <div class="header" v-if="!success">
      <span class="fail-title title-fam">{{$t('fail-title')}}</span>
    </div>
    <div class="content">
      <inline-svg class="main-svg" :src="require('@/assets/imgs/characters/mission-brief.svg')"></inline-svg>
      <div class="texts">
        <h3 class="d-title title-fam">{{$t('mission-control')}}</h3>
        <p v-if="success && outcomeStrs.length" class="mb-0">
          <span
              v-for="(s,index) in outcomeStrs"
              :key="`${s.reason}+${index}`"
              class="d-block">
            <span class="d-block font-weight-bold conclusion mt-2">{{s.type}} conclusion:</span>
            {{ s.reason }}
          </span>
        </p>
        <p v-if="!success && reasons.length" class="mb-0">
          <span
              v-for="(r,index) in reasons"
              :key="`${r.reason}+${index}`"
              class="d-block">
            <span class="d-block font-weight-bold conclusion mt-2">{{r.type}} conclusion:</span>
            {{ r.reason }}
          </span>
        </p>
      </div>
      <div class="d-flex justify-center align-center">
        <MainButton v-if="success" @click="$emit('again')" text="try-again" class="mt-5 mr-4 again-btn" />
        <MainButton @click="$emit('click')" :text="btnText" class="mt-5" />
      </div>


    </div>
  </div>
</template>

<script>
import MainButton from "@/components/MainButton";
export default {
  props: {
    success: {
      type: Boolean,
      default: true
    },
    lastMission: {
      type: Boolean,
      default: false
    },
    reasons: {
      type: Array,
      default: () => []
    },
    outcomeStrs: {
      type: Array,
      default: () => []
    }
  },
  components: {
    MainButton
  },
  data() {
    return {

    }
  },
  computed: {
    btnText() {
      if (this.success && this.lastMission) {
        return this.$t('next')
      }
      if (this.success) {
       return this.$t('next-mission')
      }
      return this.$t('try-again')

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/mission-summary.scss";
</style>