<template>
  <div class="summary">
    <div class="header">
      <inline-svg :src="require('@/assets/imgs/star.svg')"></inline-svg>
      <span class="success-title title-fam">Congratulations</span>
      <inline-svg :src="require('@/assets/imgs/star-right.svg')"></inline-svg>
    </div>
    <div class="content mt-4">
      <div class="texts">
        <p>Thank you for applying Satellite Applications Catapult's Satellite Architect game,
          we hope you are inspired to continue your space career into the future!</p>
      </div>
      <div class="d-flex justify-center align-center">
        <MainButton @click="$router.push('mission-selector')" class="mt-5" text="Back to mission selection" />
      </div>


    </div>
  </div>
</template>

<script>
import MainButton from "@/components/MainButton";
export default {
  components: {
    MainButton
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/mission-summary.scss";
</style>