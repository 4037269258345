import Vue from 'vue'
import Vuex from 'vuex'
import farming from "@/store/farming";
import climate from "@/store/climate";
import disaster from "@/store/disaster"
import axios from "@/plugins/axios";
Vue.use(Vuex)


export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {
    async getAllData() {
      try {
        const {data} = await axios.get('catapult/items/all/')
        localStorage.setItem('allData', JSON.stringify(data))
      } catch (e) {
        console.log(e)
      }

    },
    async launchMission(ctx, payload) {
      try {
        return await axios.post('catapult/launch/ ', payload)
      } catch (e) {
        throw new Error(e)
      }
    }
  },
  getters: {

  },
  modules: {
    farming,
    climate,
    disaster
  }
})
