const state = {
  imageryPayload: null,
  imageryPlatform: null,
  imageryOrbit: null,
  imageryPower: null,
  commsPayload: null,
  commsPlatform: null,
  commsOrbit: null,
  commsPower: null,
  navPayload: null,
  navPlatform: null,
  navOrbit: null,
  navPower: null,
  imagerySum: null,
  commsSum: null,
  navSum: null,
}
const mutations = {
  setImageryPayload(state, payload) {
    state.imageryPayload = payload
  },
  setImageryPlatform(state, payload) {
    state.imageryPlatform = payload
  },
  setImageryOrbit(state, payload) {
    state.imageryOrbit = payload
  },
  setImageryPower(state, payload) {
    state.imageryPower = payload
  },
  setCommsPayload(state, payload) {
    state.commsPayload = payload
  },
  setCommsPlatform(state, payload) {
    state.commsPlatform = payload
  },
  setCommsOrbit(state, payload) {
    state.commsOrbit = payload
  },
  setCommsPower(state, payload) {
    state.commsPower = payload
  },
  setNavPayload(state, payload) {
    state.navPayload = payload
  },
  setNavPlatform(state, payload) {
    state.navPlatform = payload
  },
  setNavOrbit(state, payload) {
    state.navOrbit = payload
  },
  setNavPower(state, payload) {
    state.navPower = payload
  },
  setImagerySum(state, payload) {
    state.imagerySum = payload
  },
  setCommsSum(state, payload) {
    state.commsSum = payload
  },
  setNavSum(state, payload) {
    state.navSum = payload
  }
}
const actions = {
}
const getters= {
    imageryPayload(state) {
      return state.imageryPayload
    },
    imageryPlatform(state) {
      return state.imageryPlatform
    },
    imageryOrbit(state) {
      return state.imageryOrbit
    },
    imageryPower(state) {
      return state.imageryPower
    },
    commsPayload(state) {
      return state.commsPayload
    },
    commsPlatform(state) {
      return state.commsPlatform
    },
    commsOrbit(state) {
      return state.commsOrbit
    },
    commsPower(state) {
      return state.commsPower
    },
    navPayload(state) {
      return state.navPayload
    },
    navPlatform(state) {
      return state.navPlatform
    },
    navOrbit(state) {
      return state.navOrbit
    },
    navPower(state) {
      return state.navPower
    },
    imagerySum(state) {
      return state.imagerySum
    },
    commsSum(state) {
      return state.commsSum
    },
    navSum(state) {
      return state.navSum
    }

}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}