<template>
  <div class="mission-dashboard">
    <SideTexts />
    <div class="header d-flex justify-space-between">
      <h1 class="head-title title-fam">{{ $t('dashboard-title') }}</h1>
      <button
          @click="$router.push('mission-selector')"
          class="close-btn">
        <v-icon color="black">mdi-close</v-icon>
      </button>
    </div>
    <v-row>
      <v-col cols="5">
        <div class="summary py-4">
          <h4 class="brief-t title-fam">{{$t('brief-sum')}}</h4>
          <div v-if="!briefSummary" class="sum-content">
            <p class="brief-desc mb-0">{{$t('brief-desc')}}</p>
          </div>
          <div class="points" v-else>
            <v-row v-for="p in briefPoints" :key="p.text" class="brief-point">
              <v-col cols="5" class="offset d-flex justify-end align-center">
                <inline-svg :src="p.icon"></inline-svg>
              </v-col>
              <v-col class="d-flex align-center">
                <span class="brief-text">{{ $t(p.text) }}</span>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="7">
        <v-row>
          <v-col cols="6">
            <ImageButton
                @click="briefHandler"
                :active="selectBrief"
                :btn-text="farmerObj.text"
                :icon-src="farmerObj.src"
                class="img-btn"
                :class="{
                  'img-btn-dis':  !!briefSummary
                }"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="equipmentHandler"
                :active="selectEquipment"
                btn-text="select-equip"
                :icon-src="require('@/assets/imgs/satellite-lh.svg')"
                class="img-btn"
                :class="{
                  'img-btn-dis':  !!briefSummary
                }"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import ImageButton from "@/components/ImageButton";
export default {
  components: {
    ImageButton,
    SideTexts
  },
  data() {
    return {
      selectBrief: false,
      selectEquipment: false,
      briefSummary: false,
      briefPoints: [
        {
          text: "brief-point8",
          icon: require('@/assets/imgs/characters/emergency.svg')
        },
        {
          text: "brief-point9",
          icon: require('@/assets/imgs/characters/aid.svg')
        },
        {
          text: "brief-point10",
          icon: require('@/assets/imgs/characters/government.svg')
        },
        {
          text: "brief-point11",
          icon: require('@/assets/imgs/characters/un2.svg')
        }
      ],
    }
  },
  mounted() {
    if(localStorage.getItem('disasterBrief')) {
      this.briefSummary = true
    }
  },
  computed: {
    farmerObj() {
      if(this.selectBrief) {
        return {
          text: 'mission-brief',
          src: require('@/assets/imgs/characters/emergency-a.svg')
        }
      } else {
        return {
          text: 'mission-brief',
          src: require('@/assets/imgs/characters/emergency.svg')
        }
      }
    },
  },
  watch: {
    selectBrief(v) {
      if (v) {
        this.selectEquipment = false
      }
    },
    selectEquipment(v) {
      if (v) {
        this.selectBrief = false
      }
    }
  },
  methods: {
    briefHandler() {
      this.selectBrief = !this.selectBrief
      this.$router.push('/mission-briefing-disaster')
    },
    equipmentHandler() {
      this.selectEquipment = !this.selectEquipment
      this.$router.push('/satellite-selection-disaster')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/mission-dashboard.scss";
</style>