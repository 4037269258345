<template>
  <div class="imagery-wrap">
    <SideTexts />
    <div class="header">
      <button class="back-btn" @click="backHandler">
        <inline-svg :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
      </button>
      <h1 class="head-title title-fam">{{$t('c-payload-title')}}</h1>
    </div>
    <v-row>
      <v-col cols="4">
        <div class="optical-wrap">
          <div class="optical-head flex-start">
            <h3 class="title">{{ leftSideContent?.name }}</h3>
            <div class="">
              <p class="mb-0 text-end">
                <inline-svg :src="require('@/assets/imgs/icons/satellite-color.svg')"></inline-svg>
              </p>
              <p class="sub">Platform</p>
            </div>
          </div>
          <div class="img-wrapper">
            <v-img
                class="opt-img"
                max-height="188px"
                max-width="378px"
                contain
                :src="leftImgSrc"></v-img>
          </div>
          <p class="opt-desc">
            {{ leftSideContent?.description }}
          </p>
          <div class="points">
            <p class="opt-point">
              <span>{{ $t('cost') }}</span>
              <span>£ {{ leftSideContent?.cost }}m</span>
            </p>
            <p class="opt-point">
              <span>{{ $t('power') }}</span>
              <span>{{ leftSideContent?.power }}</span>
            </p>
            <p class="opt-point">
              <span>{{ $t('weight') }}</span>
              <span>{{ leftSideContent?.weight }}</span>
            </p>
          </div>
          <MainButton
              @click="addHandler"
              class="add-btn"
              text="add"
          />
          <MainButton
              @click="removeHandler"
              class="add-btn"
              text="remove"
          />
        </div>
      </v-col>
      <v-col cols="8" class="">
        <v-row>
          <v-col cols="6">
            <ImageButton
                @click="lowComms = !lowComms"
                :active="lowComms"
                class="img-btn-wrap"
                btn-text="lowComms"
                :icon-src="require('@/assets/imgs/low-data-rate.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="highComms = !highComms"
                :active="highComms"
                class="img-btn-wrap"
                btn-text="highComms"
                :icon-src="require('@/assets/imgs/high-data-rate.svg')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MainButton from "@/components/MainButton";
import ImageButton from "@/components/ImageButton";

export default {
  components: {
    MainButton,
    ImageButton,
    SideTexts
  },
  data() {
    return {
      lowComms: false,
      highComms: false,
      farming: false,
      climate: false,
      disaster: false,
      items: []
    }
  },
  mounted() {
    if(this.$route.query.farming) {
      this.farming = true
      this.checkIfAlreadySelected('farmingCommsPayload')
    }
    if(this.$route.query.climate) {
      this.climate = true
      this.checkIfAlreadySelected('climateCommsPayload')
    }
    if(this.$route.query.disaster) {
      this.disaster = true
      this.checkIfAlreadySelected('disasterCommsPayload')
    }
    this.items = JSON.parse(localStorage.getItem('allData')).payload_items
        .filter(i => i.type === 'communications')
  },
  computed: {
    leftSideContent() {
      if (this.highComms) {
        return this.items.find(i => i.item_id === 'payload:communications:high_data_rate')
      } else {
        return this.items.find(i => i.item_id === 'payload:communications:low_data_rate')
      }
    },
    leftImgSrc() {
      if(this.highComms) {
        return require('@/assets/imgs/comms-high.jpg')
      } else {
        return require('@/assets/imgs/comms-low.jpg')
      }
    }
  },
  watch: {
    lowComms(v) {
      if (v) {
        this.highComms = false
      }
    },
    highComms(v) {
      if (v) {
        this.lowComms = false
      }
    },
  },
  methods: {
    addHandler() {
      if(this.farming) {
        this.$router.push(`satellite-selection-farming`)
        localStorage.setItem('farmingCommsPayload', JSON.stringify(this.leftSideContent))
      }
      if(this.climate) {
        this.$router.push(`satellite-selection-deforestation`)
        localStorage.setItem('climateCommsPayload', JSON.stringify(this.leftSideContent))
      }
      if(this.disaster) {
        this.$router.push(`satellite-selection-disaster`)
        localStorage.setItem('disasterCommsPayload', JSON.stringify(this.leftSideContent))
      }
    },
    backHandler() {
      if(this.farming) {
        this.$router.push('satellite-selection-farming')
      }
      if(this.climate) {
        this.$router.push('satellite-selection-deforestation')
      }
      if(this.disaster) {
        this.$router.push('satellite-selection-disaster')
      }
    },
    checkIfAlreadySelected(name) {
      if(JSON.parse(localStorage.getItem(name))){
        const id = JSON.parse(localStorage.getItem(name)).item_id
        return id === 'payload:communications:low_data_rate' ? this.lowComms = true
            : this.highComms = true
      }
    },
    removeHandler() {
      if(this.farming) {
        localStorage.removeItem('farmingCommsPayload')
      }
      if(this.climate) {
        localStorage.removeItem('climateCommsPayload')
      }
      if(this.disaster) {
        localStorage.removeItem('disasterCommsPayload')
      }
      this.lowComms = false
      this.highComms = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/imagery-payload.scss";
</style>