<template>
  <DialogueSummary
      :success="success"
      :reasons="reasons"
      :outcome-strs="outcomeStrs"
      @click="missionHandler"
      @again="$router.push('/satellite-selection-deforestation')"
  />
</template>

<script>
import DialogueSummary from "@/components/DialogueSummary";
export default {
  components: {
    DialogueSummary
  },
  data() {
    return {
      success: null,
      reasons: [],
      outcomeStrs: []
    }
  },
  computed: {
    imagerySum() {
      return JSON.parse(localStorage.getItem('climateImagerySum'))
    },
    commsSum() {
      return JSON.parse(localStorage.getItem('climateCommsSum'))
    },
    navSum() {
      return JSON.parse(localStorage.getItem('climateNavSum'))
    }
  },
  created() {
    const sums = [
        {
          type: 'Imagery',
          ...this.imagerySum
        },
        {
          type: 'Communications',
          ...this.commsSum
        },
        {
          type: 'Navigation',
          ...this.navSum
        }
      ]
    const exists = sums?.filter(i => i.reason !== null)
    const negative = exists?.filter(i => i.outcome_status === 'fail')
    console.log(negative)
    if (negative.length) {
      this.success = false
      negative?.forEach(i => (i.reason && this.reasons.indexOf(i.reason) === -1) ? this.reasons.push(i) : null)
    } else {
      this.success = true
      exists.forEach(i => i.reason ? this.outcomeStrs.push(i) : null)
    }
  },
  methods: {
    missionHandler() {
      if(this.success) {
        this.$router.push('mission-selector')
        localStorage.setItem('climateSuccess', 'success')
      } else this.$router.push('/satellite-selection-deforestation')
    }
  }
}
</script>