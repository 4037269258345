<template>
  <div class="brief">
    <inline-svg class="person-image" :src="imgSrc"></inline-svg>
    <p class="name">
      {{ $t(name) }}
    </p>
    <p class="desc">
      {{ $t(desc) }}
    </p>
    <div class="btns-wrap">
      <button
          v-if="back"
          @click="$emit('backClick')"
          class="back-btn">
        <inline-svg :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
      </button>
      <MainButton @click="$emit('click')" right-icon text="next" class="main" />
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/MainButton";

export default {
  name: "MissionBriefing",
  components: {
    MainButton
  },

  props: {
    imgSrc: {
      type: String,
      default: require('@/assets/imgs/characters/farmer.svg')
    },
    name: {
      type: String,
      default: 'farmer'
    },
    desc: {
      type: String,
      default: 'farmer-desc'
    },
    back: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style lang="scss" scoped>
.brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;
  background: #000000;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;

  .person-image {
    width: 144px;
    height: 144px;
  }

  .name {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    text-align: center;
    color: #FFFFFF;
  }

  .btns-wrap {
    display: flex;
    width: 100%;
    gap: 8px;


    .main {
      width: 100%;
    }
  }
}
</style>