<template>
  <div class="imagery-wrap">
    <SideTexts />
    <div class="header">
      <button class="back-btn" @click="backHandler">
        <inline-svg :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
      </button>
      <h1 class="head-title title-fam">{{$t('i-platform-title')}}</h1>
    </div>
    <v-row>
      <v-col cols="4">
        <div class="optical-wrap">
          <div class="optical-head flex-start">
            <h3 class="title">{{ leftSideContent?.name }}</h3>
            <div class="">
              <p class="mb-0 text-end">
                <inline-svg :src="require('@/assets/imgs/icons/satellite-color.svg')"></inline-svg>
              </p>
              <p class="sub">{{ $t('platform') }}</p>
            </div>
          </div>
          <inline-svg
              :src="leftImgSrc"
              :class="{
                'plat-svg-less': smallCons
              }"
              class="platform-svg" ></inline-svg>
          <p class="opt-desc">
            {{ leftSideContent?.description }}
          </p>
          <div class="points">
            <p class="opt-point">
              <span>{{ $t('cost') }}</span>
              <span>£ {{ leftSideContent?.cost }}m</span>
            </p>
            <p class="opt-point">
              <span>{{ $t('power_cap') }}</span>
              <span>{{ leftSideContent?.power_capacity }}</span>
            </p>
            <p class="opt-point">
              <span>{{ $t('weight_cap') }}</span>
              <span>{{ leftSideContent?.weight_capacity }}</span>
            </p>
          </div>
          <MainButton
              @click="addHandler"
              class="add-btn"
              text="add"
          />
          <MainButton
              @click="removeHandler"
              class="add-btn"
              text="remove"
          />
        </div>
      </v-col>
      <v-col cols="8">
        <v-row>
          <v-col cols="6">
            <ImageButton
                @click="smallSat = !smallSat"
                :active="smallSat"
                class="img-btn-wrap"
                btn-text="smallSat"
                :small-img="true"
                :icon-src="require('@/assets/imgs/opt-imagery.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="largeSat = !largeSat"
                :active="largeSat"
                class="img-btn-wrap"
                btn-text="largeSat"
                :icon-src="require('@/assets/imgs/satellite-lh.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="smallCons = !smallCons"
                :active="smallCons"
                class="img-btn-wrap"
                btn-text="smallCons"
                :icon-src="require('@/assets/imgs/small-const.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="largeCons = !largeCons"
                :active="largeCons"
                class="img-btn-wrap"
                btn-text="largeCons"
                :icon-src="require('@/assets/imgs/big-const.svg')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MainButton from "@/components/MainButton";
import ImageButton from "@/components/ImageButton";

export default {
  components: {
    MainButton,
    ImageButton,
    SideTexts
  },
  data() {
    return {
      smallSat: false,
      largeSat: false,
      smallCons: false,
      largeCons: false,
      farming: false,
      climate: false,
      disaster: false,
      items: [],
    }
  },
  mounted() {
    if(this.$route.query.farming) {
      this.farming = true
      this.checkIfAlreadySelected('farmingImageryPlatform')
    }
    if(this.$route.query.climate) {
      this.climate = true
      this.checkIfAlreadySelected('climateImageryPlatform')
    }
    if(this.$route.query.disaster) {
      this.disaster = true
      this.checkIfAlreadySelected('disasterImageryPlatform')
    }
    this.items = JSON.parse(localStorage.getItem('allData')).platform_items
  },
  computed: {
    leftSideContent() {
      if (this.largeSat) {
        return this.items.find(i => i.item_id === 'platform:large_sat')
      } else if(this.largeCons) {
        return this.items.find(i => i.item_id === 'platform:large_constellation')
      } else if (this.smallCons) {
        return this.items.find(i => i.item_id === 'platform:small_constellation')
      } else {
        return this.items.find(i => i.item_id === 'platform:small_sat')
      }
    },
    leftImgSrc() {
      if(this.largeSat) {
        return require('@/assets/imgs/satellite-lh.svg')
      } else if (this.smallCons) {
        return require('@/assets/imgs/small-const.svg')
      } else if (this.largeCons) {
        return require('@/assets/imgs/big-const.svg')
      } else {
        return require('@/assets/imgs/opt-imagery.svg')
      }
    },
  },
  watch: {
    smallSat(v) {
      if (v) {
        this.largeSat = false
        this.smallCons = false
        this.largeCons = false
      }
    },
    largeSat(v) {
      if (v) {
        this.smallSat = false
        this.smallCons = false
        this.largeCons = false
        this.$toast("Large satellite requires high power", {
          position: 'top-center',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    smallCons(v) {
      if (v) {
        this.smallSat = false
        this.largeSat = false
        this.largeCons = false
      }
    },
    largeCons(v) {
      if (v) {
        this.smallSat = false
        this.largeSat = false
        this.smallCons = false
      }
    }
  },
  methods: {
    addHandler() {
      if(this.farming) {
        this.$router.push(`satellite-selection-farming`)
        localStorage.setItem('farmingImageryPlatform', JSON.stringify(this.leftSideContent))
      }
      if(this.climate) {
        this.$router.push(`satellite-selection-deforestation`)
        localStorage.setItem('climateImageryPlatform', JSON.stringify(this.leftSideContent))
      }
      if(this.disaster) {
        this.$router.push(`satellite-selection-disaster`)
        localStorage.setItem('disasterImageryPlatform', JSON.stringify(this.leftSideContent))
      }
    },
    backHandler() {
      if(this.farming) {
        this.$router.push('satellite-selection-farming')
      }
      if(this.climate) {
        this.$router.push('satellite-selection-deforestation')
      }
      if(this.disaster) {
        this.$router.push('satellite-selection-disaster')
      }
    },
    checkIfAlreadySelected(name) {
      if(JSON.parse(localStorage.getItem(name))){
        const id = JSON.parse(localStorage.getItem(name)).item_id
        return id === 'platform:small_sat' ? this.smallSat = true
            : id === 'platform:large_sat' ? this.largeSat = true
                : id === 'platform:small_constellation' ? this.smallCons = true
                    : this.largeCons = true
      }
    },
    removeHandler() {
      if(this.farming) {
        localStorage.removeItem('farmingImageryPlatform')
      }
      if(this.climate) {
        localStorage.removeItem('climateImageryPlatform')
      }
      if(this.disaster) {
        localStorage.removeItem('disasterImageryPlatform')
      }
      this.smallSat= false
      this.largeSat = false
      this.smallCons = false
      this.largeCons = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/imagery-payload.scss";
</style>