<template>
  <button class="main-btn" @click="$emit('click')" :class="{ disabled }">
    <inline-svg v-if="leftIcon || icon" class="left-icon" :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
    <span class="text" v-if="!icon">{{ $t(text) }}</span>
    <inline-svg v-if="rightIcon" class="right-icon" :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
  </button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    text: {
      type: String,
      default: 'launch'
    },
    icon: {
      type: Boolean,
      default: false,
    },
    leftIcon: {
      type: Boolean,
      default: false,
    },
    rightIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
 .main-btn {
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   background: #32E875;
   border-radius: 8px;
   display: flex;
   padding: 12px 16px;
   gap: 8px;
   text-align: center;
   justify-content: center;
   align-items: center;
   height: 48px;
   svg {
     fill: #000000;
   }

   .right-icon {
     transform: rotate(180deg);
   }

   .text {
     text-transform: uppercase;
   }

   &:hover {
     background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #32E875;
     color: white;
     svg {
       fill: white;
     }
   }
   &:active {
     background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), #32E875;
     color: white;
     svg {
       fill: white;
     }
   }
 }

 .disabled {
   background: #88837C;
   color: #C2BDB6;
   cursor:  not-allowed;
   &:hover {
     background: #88837C;
     color: #C2BDB6;
     svg {
       fill: #C2BDB6;
     }
   }
   &:active {
     background: #88837C;
     color: #C2BDB6;
     svg {
       fill: #C2BDB6;
     }
   }
 }
</style>