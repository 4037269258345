<template>
  <div class="dialogue">
    <div class="header d-flex justify-end">
      <MainButton @click="$emit('click-skip')" :text="$t('next-slide')" />
    </div>
    <div class="content">
      <inline-svg class="main-svg" :src="require('@/assets/imgs/characters/mission-brief.svg')"></inline-svg>
      <div class="texts">
        <h3 class="d-title title-fam">{{$t('mission-control')}}</h3>
        <p v-if="welcome" class="first mb-0">{{$t('welcome')}}</p>
        <p v-if="welcome" class="second mb-0">{{$t('welcome-desc')}}</p>
        <p v-if="needHelp">{{$t('need-help')}}</p>
        <p v-if="satelliteTypes" class="first mb-0">{{$t('satellite-types')}}</p>
        <p v-if="mightThink" class="first mb-0">{{$t('might-think')}}</p>

        <!--  text depends on chosen button   -->
        <p v-if="descriptionButtons && showButtonsDescription" class="first mb-0">{{$t('btns-default')}}</p>
        <p v-if="payloadActive" class="first mb-0">{{$t('payload-is')}}</p>
        <p v-if="platformActive" class="first mb-0">{{$t('platform-is')}}</p>
        <p v-if="orbitActive" class="first mb-0">{{$t('orbit-is')}}</p>
        <p v-if="groundActive" class="first mb-0">{{$t('ground-is')}}</p>
        <p v-if="powerActive" class="first mb-0">{{$t('power-is')}}</p>
      </div>
    </div>
    <v-row v-if="satelliteTypes">
      <v-col cols="10" class="offset-1">
        <v-row>
          <v-col v-for="p in points" :key="p.title" cols="4" class="points">
            <div class="point-wrap">
              <p class="point mb-2">
                <inline-svg :src="p.icon"></inline-svg>
                <span class="title-fam text-uppercase">{{$t(p.title)}}</span>
              </p>
              <p class="mb-0">{{$t(p.desc)}}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="descriptionButtons">
      <v-col cols="10" class="offset-1">
        <v-row>
          <v-col cols="2" class="offset-1">
            <ImageButton
                @click="payloadActive = !payloadActive"
                :active="payloadActive"
                class="img-btn"
                :icon-src="require('@/assets/imgs/payload.svg')"
                btn-text="payload" />
          </v-col>
          <v-col cols="2">
            <ImageButton
                @click="platformActive = !platformActive"
                :active="platformActive"
                class="img-btn"
                :icon-src="require('@/assets/imgs/satellite-lh.svg')"
                btn-text="platform" />
          </v-col>
          <v-col cols="2">
            <ImageButton
                @click="orbitActive = !orbitActive"
                :active="orbitActive"
                class="img-btn"
                :icon-src="require('@/assets/imgs/orbit.svg')"
                btn-text="orbit" />
          </v-col>
          <v-col cols="2">
            <ImageButton
                @click="groundActive = !groundActive"
                :active="groundActive"
                class="img-btn"
                :icon-src="require('@/assets/imgs/satellite-dish.svg')"
                btn-text="ground" />
          </v-col>
          <v-col cols="2">
            <ImageButton
                @click="powerActive = !powerActive"
                :active="powerActive"
                class="img-btn"
                :icon-src="require('@/assets/imgs/power.svg')"
                btn-text="power" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainButton from "@/components/MainButton";
import ImageButton from "@/components/ImageButton";
export default {
  name: "Dialogue",
  components: {
    MainButton,
    ImageButton
  },
  props: {
    welcome: {
      type: Boolean,
      default: false,
    },
    needHelp: {
      type: Boolean,
      default: false,
    },
    satelliteTypes: {
      type: Boolean,
      default: false,
    },
    mightThink: {
      type: Boolean,
      default: false,
    },
    descriptionButtons: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      points: [
        {
          icon: require('@/assets/imgs/icons/eye.svg'),
          title: "imagery",
          desc: "imagery-desc"
        },
        {
          icon: require('@/assets/imgs/icons/communications.svg'),
          title: "communications",
          desc: "communications-desc"
        },
        {
          icon: require('@/assets/imgs/icons/navigation.svg'),
          title: "navigation",
          desc: "navigation-desc"
        }
      ],
      payloadActive: false,
      platformActive: false,
      orbitActive: false,
      groundActive:  false,
      powerActive: false,
    }
  },
  computed: {
    showButtonsDescription() {
      if(this.payloadActive || this.platformActive || this.orbitActive || this.groundActive || this.powerActive) {
        return false
      }
      return true
    }
  },
  watch: {
    payloadActive(v) {
      if (v) {
        this.platformActive = false
        this.orbitActive = false
        this.groundActive = false
        this.powerActive = false
      }
    },
    platformActive(v) {
      if (v) {
        this.payloadActive = false
        this.orbitActive = false
        this.groundActive = false
        this.powerActive = false
      }
    },
    orbitActive(v) {
      if (v) {
        this.payloadActive = false
        this.platformActive = false
        this.groundActive = false
        this.powerActive = false
      }
    },
    groundActive(v) {
      if (v) {
        this.payloadActive = false
        this.platformActive = false
        this.orbitActive = false
        this.powerActive = false
      }
    },
    powerActive(v) {
      if (v) {
        this.payloadActive = false
        this.platformActive = false
        this.orbitActive = false
        this.groundActive = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/pages/dialogue.scss';
</style>