<template>
  <div class="btn-wrap" >
    <button class="column-btn" @click="$emit('click')">
    <span class="left-part">
      <v-img v-if="btnType !== 'add'" class="img" max-height="24px" max-width="24px" :src="btnContent.imageSrc"></v-img>
        {{ btnContent.text }}
    </span>
      <v-img v-if="empty" max-height="19px" max-width="19px" :src="require('@/assets/imgs/icons/add.svg')"></v-img>
      <v-img v-else max-height="19px" max-width="19px" :src="require('@/assets/imgs/icons/edit.svg')"></v-img>
    </button>
    <p v-if="!empty" class="mb-0 text-center added-items">
      {{ $t(btnText.toLowerCase()) }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TableButton",
  props: {
    btnType: {
      type: String,
      default: "payload",
    },
    empty: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
    }
  },
  computed: {
    btnContent() {
      if (this.btnType === 'platform') {
        return {
          imageSrc: require('@/assets/imgs/icons/platform.svg'),
          text: this.$t('platform')
        }
      } else if (this.btnType === 'orbit') {
        return {
          imageSrc: require('@/assets/imgs/icons/orbit.svg'),
          text: this.$t('orbit')
        }
      } else if (this.btnType === 'power') {
        return {
          imageSrc: require('@/assets/imgs/icons/power.svg'),
          text: this.$t('power')
        }
      } else if (this.btnType === 'add') {
        return {
          imageSrc: '',
          text: this.$t('add')
        }
      } else {
        return {
          imageSrc: require('@/assets/imgs/icons/satellite.svg'),
          text: this.$t('payload')
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
  .column-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 12px;
    gap: 8px;
    border-radius: 8px;
    height: 50px;
    color: white;
    background: #002371;
    z-index: 10;
    position: relative;

    .left-part {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;

      .img {
        margin-right: 8px;
      }
    }
  }

  .added-items {
    background: #0F1B31;
    border-width: 0px 2px 2px 2px;
    border-style: solid;
    border-color: #002371;
    margin-top: -14px;
    border-radius: 8px;
    padding: 24px 5px 12px;
    gap: 8px;
    color: white;
    text-transform: capitalize;
  }
}
</style>