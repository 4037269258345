<template>
  <div class="welcome-wrap">
    <LoadingComponent
        v-if="showFirstAnimation"
        home-page
        flex-position="center"
    />
    <Dialogue
        v-else-if="!showFirstAnimation && !showSecondAnimation"
        :welcome="welcome"
        :need-help="needHelp"
        :satellite-types="satelliteTypes"
        :might-think="mightThink"
        :description-buttons="descriptionButtons"
        @click-skip="dialogueHandler"
    />
    <LoadingComponent
        v-if="showSecondAnimation"
        flex-position="center"
    />
  </div>
</template>

<script>
import Dialogue from "@/components/Dialogue";
import LoadingComponent from "@/components/LoadingComponent";
export default {
  components: {
    Dialogue,
    LoadingComponent
  },
  data() {
    return {
      welcome: true,
      needHelp: false,
      satelliteTypes: false,
      mightThink: false,
      descriptionButtons: false,
      showFirstAnimation: true,
      showSecondAnimation: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.showFirstAnimation = false
    }, 8000)
  },
  methods: {
     dialogueHandler() {
      if(this.welcome) {
        this.welcome = false;
        this.needHelp = true;
      } else if (this.needHelp) {
        this.needHelp = false;
        this.satelliteTypes = true;
      } else if(this.satelliteTypes) {
        this.satelliteTypes = false;
        this.mightThink = true;
      } else if(this.mightThink) {
        this.mightThink = false;
        this.descriptionButtons = true
      } else if(this.descriptionButtons) {
        this.showSecondAnimation = true
        setTimeout(() => {
          this.$router.push('mission-selector')
        }, 8000)

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-wrap {
  height: 100%;
}
</style>