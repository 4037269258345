<template>
  <div>
    <div class="left-text">
      <span>{{ $t('left-text') }}</span>
      <span>
        //////////////////////////////////////////////////////////////////////////
      </span>
      <span>
        v1.2.01
      </span>
    </div>
    <div class="right-text">
      <span>{{ $t('right-text.1') }}</span>
      <span>///</span>
      <span>{{ $t('right-text.2') }}</span>
      <span>///</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideTexts"
}
</script>

