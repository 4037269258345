<template>
  <div class="imagery-wrap">
    <SideTexts />
    <div class="header">
      <button class="back-btn" @click="backHandler">
        <inline-svg :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
      </button>
      <h1 class="head-title title-fam">{{$t('c-power-title')}}</h1>
    </div>
    <v-row>
      <v-col cols="4">
        <div class="optical-wrap">
          <div class="optical-head flex-start">
            <h3 class="title">{{ leftSideContent?.name }}</h3>
            <div class="">
              <p class="mb-0 text-end">
                <inline-svg :src="require('@/assets/imgs/icons/satellite-color.svg')"></inline-svg>
              </p>
              <p class="sub">Platform</p>
            </div>
          </div>
          <div class="img-wrapper power-svg-wrap">
            <inline-svg
                :src="highPower ? require('@/assets/imgs/icons/large-battery.svg') : require('@/assets/imgs/icons/small-battery.svg')"
            />
          </div>
          <p class="opt-desc">
            {{ leftSideContent?.description }}
          </p>
          <div class="points">
            <p class="opt-point">
              <span>{{ $t('power') }}</span>
              <span>{{ leftSideContent?.power_capacity }}</span>
            </p>
          </div>
          <MainButton
              @click="addHandler"
              class="add-btn"
              text="add"
          />
          <MainButton
              @click="removeHandler"
              class="add-btn"
              text="remove"
          />
        </div>
      </v-col>
      <v-col cols="8" class="d-flex align-center">
        <v-row>
          <v-col cols="6">
            <ImageButton
                @click="lowPower = !lowPower"
                :active="lowPower"
                class="img-btn-wrap"
                btn-text="lowPower"
                :icon-src="require('@/assets/imgs/icons/low-power.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="highPower = !highPower"
                :active="highPower"
                class="img-btn-wrap"
                btn-text="highPower"
                :icon-src="require('@/assets/imgs/icons/high-power.svg')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MainButton from "@/components/MainButton";
import ImageButton from "@/components/ImageButton";

export default {
  components: {
    MainButton,
    ImageButton,
    SideTexts
  },
  data() {
    return {
      lowPower: false,
      highPower: false,
      farming: false,
      climate: false,
      disaster: false,
      items: [],
    }
  },
  mounted() {
    if(this.$route.query.farming) {
      this.farming = true
      this.checkIfAlreadySelected('farmingCommsPower')
    }
    if(this.$route.query.climate) {
      this.climate = true
      this.checkIfAlreadySelected('climateCommsPower')
    }
    if(this.$route.query.disaster) {
      this.disaster = true
      this.checkIfAlreadySelected('disasterCommsPower')
    }
    this.items = JSON.parse(localStorage.getItem('allData')).power_items
  },
  computed: {
    leftSideContent() {
      if (this.highPower) {
        return this.items.find(i => i.item_id === 'power:high')
      } else {
        return this.items.find(i => i.item_id === 'power:low')
      }
    }
  },
  watch: {
    lowPower(v) {
      if (v) {
        this.highPower = false
      }
    },
    highPower(v) {
      if (v) {
        this.lowPower = false
      }
    },
  },
  methods: {
    addHandler() {
      if(this.farming) {
        this.$router.push(`satellite-selection-farming`)
        localStorage.setItem('farmingCommsPower', JSON.stringify(this.leftSideContent))
      }
      if(this.climate) {
        this.$router.push(`satellite-selection-deforestation`)
        localStorage.setItem('climateCommsPower', JSON.stringify(this.leftSideContent))
      }
      if(this.disaster) {
        this.$router.push(`satellite-selection-disaster`)
        localStorage.setItem('disasterCommsPower', JSON.stringify(this.leftSideContent))
      }
    },
    backHandler() {
      if(this.farming) {
        this.$router.push('satellite-selection-farming')
      }
      if(this.climate) {
        this.$router.push('satellite-selection-deforestation')
      }
      if(this.disaster) {
        this.$router.push('satellite-selection-disaster')
      }
    },
    checkIfAlreadySelected(name) {
      if(JSON.parse(localStorage.getItem(name))){
        const id = JSON.parse(localStorage.getItem(name)).item_id
        return id === 'power:low' ? this.lowPower = true
            : this.highPower = true
      }
    },
    removeHandler() {
      if(this.farming) {
        localStorage.removeItem('farmingCommsPower')
      }
      if(this.climate) {
        localStorage.removeItem('climateCommsPower')
      }
      if(this.disaster) {
        localStorage.removeItem('disasterCommsPower')
      }
      this.lowPower= false
      this.highPower = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/imagery-payload.scss";
</style>