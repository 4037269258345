<template>
  <DialogueSummary
      :success="success"
      :reasons="reasons"
      :outcome-strs="outcomeStrs"
      :last-mission="true"
      @click="missionHandler"
      @again="$router.push('/satellite-selection-disaster')"
  />
</template>

<script>
import DialogueSummary from "@/components/DialogueSummary";
export default {
  components: {
    DialogueSummary
  },
  data() {
    return {
      success: null,
      reasons: [],
      outcomeStrs: []
    }
  },
  computed: {
    imagerySum() {
      return JSON.parse(localStorage.getItem('disasterImagerySum'))
    },
    commsSum() {
      return JSON.parse(localStorage.getItem('disasterCommsSum'))
    },
    navSum() {
      return JSON.parse(localStorage.getItem('disasterNavSum'))
    }
  },
  created() {
    const sums = [
      {
        type: 'Imagery',
        ...this.imagerySum
      },
      {
        type: 'Communications',
        ...this.commsSum
      },
      {
        type: 'Navigation',
        ...this.navSum
      }
    ]
    const exists = sums?.filter(i => i.reason !== null)
    const negative = exists?.filter(i => i.outcome_status === 'fail')
    if (negative.length) {
      this.success = false
      negative?.forEach(i => (i.reason && this.reasons.indexOf(i.reason) === -1) ? this.reasons.push(i) : null)
    } else {
      this.success = true
      exists.forEach(i => i.reason ? this.outcomeStrs.push(i) : null)
    }
  },
  methods: {
    missionHandler() {
      if(this.success) {
        this.$router.push('final')
        localStorage.setItem('disasterSuccess', 'success')
      } else this.$router.push('/satellite-selection-disaster')
    }
  }
}
</script>