<template>
  <div class="mission-briefing">
    <SideTexts />
    <div class="header">
      <h1 class="head-title title-fam text-uppercase">{{ $t('mission-brief') }}</h1>
    </div>
    <div class="content">
      <MissionBriefing
          v-if="farmer"
          class="brief"
          @click="farmerHandler"
      />
      <MissionBriefing
          v-if="police"
          @click="policeHandler"
          @backClick="policeBackHandler"
          :img-src="require('@/assets/imgs/characters/policy-maker.svg')"
          name="policy"
          desc="policy-desc"
          back
          class="brief"
      />
      <MissionBriefing
          v-if="space"
          @click="spaceHandler"
          @backClick="spaceBackHandler"
          :img-src="require('@/assets/imgs/characters/uksa.svg')"
          name="space"
          desc="space-desc"
          back
          class="brief"
      />

    </div>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MissionBriefing from "@/components/MissionBriefing";
export default {
  components: {
    MissionBriefing,
    SideTexts
  },
  data() {
    return {
      farmer: true,
      police: false,
      space: false,
    }
  },
  methods: {
    farmerHandler() {
      this.farmer = false
      this.police = true
    },
    policeHandler() {
      this.police = false
      this.space = true
    },
    policeBackHandler() {
      this.police = false
      this.farmer = true
    },
    spaceHandler() {
      localStorage.setItem('farmingBrief', 'true')
      this.$router.push('mission-dashboard-farming')
    },
    spaceBackHandler() {
      this.space = false
      this.police = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/mission-briefing.scss";
</style>