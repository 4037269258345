<template>
  <div class="imagery-wrap">
    <SideTexts />
    <div class="header">
      <button class="back-btn" @click="backHandler">
        <inline-svg :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
      </button>
      <h1 class="head-title title-fam">{{$t('i-payload-title')}}</h1>
    </div>
    <v-row>
      <v-col cols="4">
        <div class="optical-wrap">
          <div class="optical-head flex-start">
            <h3 class="title">{{ leftSideContent?.name }}</h3>
            <div class="">
              <p class="mb-0 text-end">
                <inline-svg :src="require('@/assets/imgs/icons/satellite-color.svg')"></inline-svg>
              </p>
              <p class="sub">Payload</p>
            </div>
          </div>
          <div class="img-wrapper">
            <v-img
                class="opt-img"
                max-height="188px"
                max-width="378px"
                cover
                :src="leftImgSrc"></v-img>
          </div>
          <p class="opt-desc">
            {{ leftSideContent?.description }}
          </p>
          <div class="points">
            <p class="opt-point">
              <span>{{ $t('cost') }}</span>
              <span>£ {{ leftSideContent?.cost }}m</span>
            </p>
            <p class="opt-point">
              <span>{{ $t('power') }}</span>
              <span>{{ leftSideContent?.power }}</span>
            </p>
            <p class="opt-point">
              <span>{{ $t('weight') }}</span>
              <span>{{ leftSideContent?.weight }}</span>
            </p>
          </div>
          <MainButton
              @click="addHandler"
              class="add-btn"
              text="add"
          />
          <MainButton
              @click="removeHandler"
              class="add-btn"
              text="remove"
          />
        </div>
      </v-col>
      <v-col cols="8">
        <v-row class="btns-wrap">
          <v-col cols="6">
            <ImageButton
                @click="opticalLow = !opticalLow"
                :active="opticalLow"
                class="img-btn-wrap"
                btn-text="optImageryLow"
                :icon-src="require('@/assets/imgs/opt-imagery.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="opticalHigh = !opticalHigh"
                :active="opticalHigh"
                class="img-btn-wrap"
                btn-text="optImageryHigh"
                :icon-src="require('@/assets/imgs/opt-imagery.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="radarLow = !radarLow"
                :active="radarLow"
                class="img-btn-wrap"
                btn-text="radarHigh"
                :icon-src="require('@/assets/imgs/satellite-lh.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="radarHigh = !radarHigh"
                :active="radarHigh"
                class="img-btn-wrap"
                btn-text="radarLow"
                :icon-src="require('@/assets/imgs/satellite-lh.svg')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MainButton from "@/components/MainButton";
import ImageButton from "@/components/ImageButton";

export default {
  components: {
    MainButton,
    ImageButton,
    SideTexts
  },
  data() {
    return {
      opticalLow: false,
      opticalHigh: false,
      radarLow: false,
      radarHigh: false,
      farming: false,
      climate: false,
      disaster: false,
      items: [],
    }
  },
  mounted() {
    if(this.$route.query.farming) {
      this.farming = true
      this.checkIfAlreadySelected('farmingImageryPayload')
    }
    if(this.$route.query.climate) {
      this.climate = true
      this.checkIfAlreadySelected('climateImageryPayload')
    }
    if(this.$route.query.disaster) {
      this.disaster = true
      this.checkIfAlreadySelected('disasterImageryPayload')
    }
    this.items = JSON.parse(localStorage.getItem('allData')).payload_items
        .filter(i => i.type === 'imagery')
  },
  computed: {
    leftSideContent() {
      if (this.opticalHigh) {
        return this.items.find(i => i.item_id === 'payload:imagery:high_res_optical')
      } else if(this.radarHigh) {
        return this.items.find(i => i.item_id === 'payload:imagery:high_res_radar')
      } else if (this.radarLow) {
        return this.items.find(i => i.item_id === 'payload:imagery:low_res_radar')
      } else {
        return this.items.find(i => i.item_id === 'payload:imagery:low_res_optical')
      }
    },
    leftImgSrc() {
      if(this.radarHigh) {
        return require('@/assets/imgs/high-rad.jpg')
      } else if (this.opticalHigh) {
        return require('@/assets/imgs/high-opt.jpg')
      } else if (this.radarLow) {
        return require('@/assets/imgs/low-rad.jpg')
      } else {
        return require('@/assets/imgs/low-opt.jpg')

      }
    }
  },
  watch: {
    opticalLow(v) {
      if (v) {
        this.opticalHigh = false
        this.radarLow = false
        this.radarHigh = false
      }
    },
    opticalHigh(v) {
      if (v) {
        this.opticalLow = false
        this.radarLow = false
        this.radarHigh = false
      }
    },
    radarLow(v) {
      if (v) {
        this.opticalLow = false
        this.opticalHigh = false
        this.radarHigh = false
      }
    },
    radarHigh(v) {
      if (v) {
        this.opticalLow = false
        this.opticalHigh = false
        this.radarLow = false
      }
    }
  },
  methods: {
    addHandler() {
      if(this.farming) {
        this.$router.push(`satellite-selection-farming`)
        localStorage.setItem('farmingImageryPayload', JSON.stringify(this.leftSideContent))
      }
      if(this.climate) {
        this.$router.push(`satellite-selection-deforestation`)
        localStorage.setItem('climateImageryPayload', JSON.stringify(this.leftSideContent))
      }
      if(this.disaster) {
        this.$router.push(`satellite-selection-disaster`)
        localStorage.setItem('disasterImageryPayload', JSON.stringify(this.leftSideContent))
      }
    },
    backHandler() {
      if(this.farming) {
        this.$router.push('satellite-selection-farming')
      }
      if(this.climate) {
        this.$router.push('satellite-selection-deforestation')
      }
      if(this.disaster) {
        this.$router.push('satellite-selection-disaster')
      }
    },
    checkIfAlreadySelected(name) {
      if(JSON.parse(localStorage.getItem(name))){
        const id = JSON.parse(localStorage.getItem(name)).item_id
        return id === 'payload:imagery:low_res_optical' ? this.opticalLow = true
            : id === 'payload:imagery:high_res_optical' ? this.opticalHigh = true
                : id === 'payload:imagery:high_res_radar' ? this.radarHigh = true
                    : this.radarLow = true
      }
    },
    removeHandler() {
      if(this.farming) {
        localStorage.removeItem('farmingImageryPayload')
      }
      if(this.climate) {
        localStorage.removeItem('climateImageryPayload')
      }
      if(this.disaster) {
        localStorage.removeItem('disasterImageryPayload')
      }
      this.opticalLow = false
      this.opticalHigh = false
      this.radarLow = false
      this.radarHigh = false
    }
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/imagery-payload.scss";
.btns-wrap {
  //min-height: 728px;
  //@media (max-width: 1300px) {
  //  min-height: 800px;
  //}
  //@media (max-width: 1376px) {
  //  min-height: 759px;
  //}
}
</style>