<template>
  <div v-if="!launchingAnimation && !stfcAnimation" class="wrapper">
    <section class="header d-flex justify-space-between">
      <h1 class="satellite-title title-fam">{{$t('satellite-title')}}</h1>
      <button @click="$router.push('/mission-dashboard-deforestation')" class="close-btn"><v-icon color="black">mdi-close</v-icon></button>
    </section>
    <section class="content">
      <v-row>
        <v-col cols="3">
          <div class="column-wrap">
            <div class="column-head">
              <v-img max-height="22px" max-width="22px" :src="require('@/assets/imgs/icons/eye.svg')"></v-img>
              <h4 class="column-title title-fam">{{$t('imagery')}}</h4>
            </div>
            <div class="column-content">
              <TableButton
                  :btn-text="imageryPayload?.name"
                  :empty="!imageryPayload"
                  @click="$router.push(`imagery-payload-options?climate=true`)"
                  btn-type="payload"
              />
              <TableButton
                  :btn-text="imageryPlatform?.name"
                  :empty="!imageryPlatform"
                  @click="$router.push(`imagery-platform-options?climate=true`)"
                  class="mt-4"
                  btn-type="platform"
              />
              <TableButton
                  :btn-text="imageryOrbit?.name"
                  :empty="!imageryOrbit"
                  @click="$router.push(`imagery-orbit-options?climate=true`)"
                  class="mt-4"
                  btn-type="orbit"
              />
              <TableButton
                  :btn-text="imageryPower?.name"
                  :empty="!imageryPower"
                  @click="$router.push(`imagery-power-options?climate=true`)"
                  class="mt-4"
                  btn-type="power"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="column-wrap">
            <div class="column-head">
              <v-img max-height="22px" max-width="22px" :src="require('@/assets/imgs/icons/communications.svg')"></v-img>
              <h4 class="column-title title-fam">{{$t('communications')}}</h4>
            </div>
            <div class="column-content">
              <TableButton
                  :btn-text="commsPayload?.name"
                  :empty="!commsPayload"
                  @click="$router.push(`comms-payload-options?climate=true`)"
                  btn-type="payload"
              />
              <TableButton
                  :btn-text="commsPlatform?.name"
                  :empty="!commsPlatform"
                  @click="$router.push(`comms-platform-options?climate=true`)"
                  class="mt-4"
                  btn-type="platform"
              />
              <TableButton
                  :btn-text="commsOrbit?.name"
                  :empty="!commsOrbit"
                  @click="$router.push(`comms-orbit-options?climate=true`)"
                  class="mt-4"
                  btn-type="orbit"
              />
              <TableButton
                  :btn-text="commsPower?.name"
                  :empty="!commsPower"
                  @click="$router.push(`comms-power-options?climate=true`)"
                  class="mt-4"
                  btn-type="power"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="column-wrap">
            <div class="column-head">
              <v-img max-height="22px" max-width="22px" :src="require('@/assets/imgs/icons/navigation.svg')"></v-img>
              <h4 class="column-title title-fam">{{$t('navigation')}}</h4>
            </div>
            <div class="column-content">
              <TableButton
                  :btn-text="navPayload?.name"
                  :empty="!navPayload"
                  @click="$router.push(`nav-payload-options?climate=true`)"
                  btn-type="payload"
              />
              <TableButton
                  :btn-text="navPlatform?.name"
                  :empty="!navPlatform"
                  @click="$router.push(`nav-platform-options?climate=true`)"
                  class="mt-4"
                  btn-type="platform"
              />
              <TableButton
                  :btn-text="navOrbit?.name"
                  :empty="!navOrbit"
                  @click="$router.push(`nav-orbit-options?climate=true`)"
                  class="mt-4"
                  btn-type="orbit"
              />
              <TableButton
                  :btn-text="navPower?.name"
                  :empty="!navPower"
                  @click="$router.push(`nav-power-options?climate=true`)"
                  class="mt-4"
                  btn-type="power"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="budget-column">
            <div class="budget-content">
              <h4 class="budget-title">{{$t('budget')}}</h4>
              <p class="budget-point">
                <span>{{ $t('cost') }}</span>
                <span>£ {{ totalCost }}<span v-if="totalCost > 0">m</span></span>
              </p>
              <p class="budget-point">
                <span>{{ $t('power') }}</span>
                <span>{{ totalPower }}</span>
              </p>
              <p class="budget-point">
                <span>{{ $t('weight') }}</span>
                <span>{{ totalWeight }}</span>
              </p>
            </div>
            <MainButton @click="launchMission" />
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
  <LoadingComponent
      v-else-if="launchingAnimation"
      :animation-steps="20"
      flex-position="center"
      launch
      :hub="false"
      text-width="475.7px"
  />
  <LoadingComponent
      v-else-if="stfcAnimation"
      :animation-steps="36"
      flex-position="center"
      launch
      stfc
      :hub="false"
      text-width="918.06px"
  />
</template>

<script>
import TableButton from "@/components/TableButton";
import MainButton from "@/components/MainButton";
import LoadingComponent from "@/components/LoadingComponent";

export default {
  name: 'SatelliteSelection',
  components: {
    TableButton,
    MainButton,
    LoadingComponent
  },
  data() {
    return {
      launchingAnimation: false,
      stfcAnimation: false,
      imageryOk: false,
      commsOk: false,
      navOk: false,
    }
  },
  computed: {
    imageryPayload() {
      return localStorage.getItem('climateImageryPayload')
          ? JSON.parse(localStorage.getItem('climateImageryPayload')) : null
    },
    imageryPlatform() {
      return localStorage.getItem('climateImageryPlatform')
          ? JSON.parse(localStorage.getItem('climateImageryPlatform')) : null
    },
    imageryOrbit() {
      return localStorage.getItem('climateImageryOrbit')
          ? JSON.parse(localStorage.getItem('climateImageryOrbit')) : null
    },
    imageryPower() {
      return localStorage.getItem('climateImageryPower')
          ? JSON.parse(localStorage.getItem('climateImageryPower')) : null
    },
    commsPayload() {
      return localStorage.getItem('climateCommsPayload')
          ? JSON.parse(localStorage.getItem('climateCommsPayload')) : null
    },
    commsPlatform() {
      return localStorage.getItem('climateCommsPlatform')
          ? JSON.parse(localStorage.getItem('climateCommsPlatform')) : null
    },
    commsOrbit() {
      return localStorage.getItem('climateCommsOrbit')
          ? JSON.parse(localStorage.getItem('climateCommsOrbit')) : null
    },
    commsPower() {
      return localStorage.getItem('climateCommsPower')
          ? JSON.parse(localStorage.getItem('climateCommsPower')) : null
    },
    navPayload() {
      return localStorage.getItem('climateNavPayload')
          ? JSON.parse(localStorage.getItem('climateNavPayload')) : null
    },
    navPlatform() {
      return localStorage.getItem('climateNavPlatform')
          ? JSON.parse(localStorage.getItem('climateNavPlatform')) : null
    },
    navOrbit() {
      return localStorage.getItem('climateNavOrbit')
          ? JSON.parse(localStorage.getItem('climateNavOrbit')) : null
    },
    navPower() {
      return localStorage.getItem('climateNavPower')
          ? JSON.parse(localStorage.getItem('climateNavPower')) : null
    },
    totalCost() {
      return (this.imageryPayload?.cost || 0) + (this.imageryPlatform?.cost || 0)
          + (this.imageryOrbit?.cost || 0) + (this.commsPayload?.cost || 0)
          + (this.commsPlatform?.cost || 0) + (this.commsOrbit?.cost || 0)
          + (this.navPayload?.cost || 0) + (this.navPlatform?.cost || 0)
          + (this.navOrbit?.cost || 0)
    },
    totalPower() {
      return (this.imageryPayload?.power || 0) + (this.imageryPower?.power_capacity || 0)
          + (this.commsPayload?.power || 0) + (this.commsPower?.power_capacity || 0)
          + (this.navPayload?.power || 0) + (this.navPower?.power_capacity || 0)
    },
    totalWeight() {
      return (this.imageryPayload?.weight || 0) + (this.commsPayload?.weight || 0)
          + (this.navPayload?.weight || 0)
    }
  },
  created() {
    localStorage.removeItem('climateImagerySum')
    localStorage.removeItem('climateCommsSum')
    localStorage.removeItem('climateNavSum')
  },
  methods: {
    async launchMission() {
      try {
        if (this.imageryPayload || this.imageryPlatform || this.imageryOrbit || this.imageryPower) {
          const { data } = await this.$store.dispatch('launchMission', {
            mission: "deforestation",
            satellite: "imagery",
            payload_item_id: this.imageryPayload?.id,
            platform_item_id: this.imageryPlatform?.id,
            orbit_item_id: this.imageryOrbit?.id,
            power_options_item_id: this.imageryPower?.id
          })
          if(data.error_message) {
            this.$toast(data.error_message, {
              position: 'top-center',
              type: 'error'
            });
            return
          }
          localStorage.setItem('climateImagerySum', JSON.stringify(data))
          this.imageryOk = true
        }

        if (this.commsPayload || this.commsPlatform || this.commsOrbit || this.commsPower) {
          const { data } = await this.$store.dispatch('launchMission', {
            mission: "deforestation",
            satellite: "communications",
            payload_item_id: this.commsPayload?.id,
            platform_item_id: this.commsPlatform?.id,
            orbit_item_id: this.commsOrbit?.id,
            power_options_item_id: this.commsPower?.id
          })
          if(data.error_message) {
            this.$toast(data.error_message, {
              position: 'top-center',
              type: 'error'
            });
            return
          }
          localStorage.setItem('climateCommsSum', JSON.stringify(data))
          this.commsOk = true
        }

        if (this.navPayload || this.navPlatform || this.navOrbit || this.navPower) {
          const { data } = await this.$store.dispatch('launchMission', {
            mission: "deforestation",
            satellite: "navigation",
            payload_item_id: this.navPayload?.id,
            platform_item_id: this.navPlatform?.id,
            orbit_item_id: this. navOrbit?.id,
            power_options_item_id: this.navPower?.id
          })
          if(data.error_message) {
            this.$toast(data.error_message, {
              position: 'top-center',
              type: 'error'
            });
            return
          }
          localStorage.setItem('climateNavSum', JSON.stringify(data))
          this.navOk = true
        }

        if(this.imageryOk || this.commsOk || this.navOk) {

          this.launchingAnimation = true

          setTimeout(() => {
            this.launchingAnimation = false
            this.stfcAnimation = true
          }, 8000)

          setTimeout(() => {
            this.$router.push('/mission-summary-deforestation')
          }, 15500)
        } else {
          this.$toast("Please select options in at least one of the categories", {
            position: 'top-center',
            type: 'error'
          });
        }

      } catch (e) {
        this.$toast(e.message.replace('AxiosError: ', ''), {
          position: 'top-center',
          type: 'error'
        });
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/satellite-selection";
</style>