<template>
  <div class="mission-select">
    <div class="header d-flex justify-space-between">
      <h1 class="head-title title-fam">{{$t('mission-title')}}</h1>
      <MainButton @click="nextBtnHandler" text="next" right-icon />
    </div>
    <v-row>
      <v-col cols="4">
        <MissionSelector
            @click="farming = true"
            :is-active="farming"
            :completed="firstCompleted"
            :icon-src="require('@/assets/imgs/icons/tractor.svg')"
        />
      </v-col>
      <v-col cols="4">
        <MissionSelector
            @click="climate = true"
            :is-active="climate"
            :locked="secondLocked"
            :completed="secondCompleted"
            desc="Help tackle deforestation with satellites!"
            :img-src="require('@/assets/imgs/climate-bg.jpg')"
            text="climate"
        />
      </v-col>
      <v-col cols="4">
        <MissionSelector
            @click="disaster = true"
            :is-active="disaster"
            :completed="thirdCompleted"
            :locked="thirdLocked"
            :icon-src="require('@/assets/imgs/icons/tornado.svg')"
            :img-src="require('@/assets/imgs/disaster-bg.jpg')"
            disaster
            text="disaster"
            desc="Help people to manage the aftermath of an earthquake!"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainButton from "@/components/MainButton";
import MissionSelector from "@/components/MissionSelector";

export default {
  components: {
    MainButton,
    MissionSelector
  },
  data() {
    return {
      farming: false,
      climate: false,
      disaster: false,
      secondLocked: true,
      thirdLocked: true,
      firstCompleted: false,
      secondCompleted: false,
      thirdCompleted: false,
    }
  },
  mounted() {
    if(localStorage.getItem('farmingSuccess')) {
      this.secondLocked = false
      this.firstCompleted = true
    }
    if(localStorage.getItem('climateSuccess')) {
      this.thirdLocked = false
      this.secondCompleted = true
    }
    if(localStorage.getItem('disasterSuccess')) {
      this.thirdCompleted = true
    }

  },
  watch: {
    farming(v) {
      if (v) {
        this.climate = false
        this.disaster = false
      }
    },
    climate(v) {
      if (v) {
        this.farming = false
        this.disaster = false
      }
    },
    disaster(v) {
      if (v) {
        this.farming = false
        this.climate = false
      }
    }
  },
  methods: {
    nextBtnHandler() {
      if(this.farming) {
        this.$router.push('mission-dashboard-farming');
      } else if (this.climate) {
        this.$router.push('mission-dashboard-deforestation');
      } else if (this.disaster) {
        this.$router.push('mission-dashboard-disaster');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/mission-selector.scss";
</style>