import Vue from 'vue'
import VueRouter from 'vue-router'
import ImageryPayloadOptions from "@/views/ImageryPayloadOptions";
import ImageryPlatformOptions from "@/views/ImageryPlatformOptions";
import ImageryOrbitOptions from "@/views/ImageryOrbitOptions";
import ImageryPowerOptions from "@/views/ImageryPowerOptions";
import CommsPayloadOptions from "@/views/CommsPayloadOptions";
import CommsPlatformOptions from "@/views/CommsPlatformOptions";
import CommsOrbitOptions from "@/views/CommsOrbitOptions";
import CommsPowerOptions from "@/views/CommsPowerOptions";
import NavPayloadOptions from "@/views/NavPayloadOptions";
import NavPlatformOptions from "@/views/NavPlatformOptions";
import NavOrbitOptions from "@/views/NavOrbitOptions";
import NavPowerOptions from "@/views/NavPowerOptions";
import MissionSelector from "@/views/MissionSelector";
import WelcomePage from "@/views/WelcomePage";
import MissionBriefingFarming from "@/views/MissionBriefingFarming";
import MissionBriefingClimate from "@/views/MissionBriefingClimate";
import MissionBriefingDisaster from "@/views/MissionBriefingDisaster";
import MissionDashboardFarming from "@/views/MissionDashboardFarming";
import MissionDashboardClimate from "@/views/MissionDashboardClimate";
import MissionDashboardDisaster from "@/views/MissionDashboardDisaster";
import SatelliteSelectionFarming from "@/views/SatelliteSelectionFarming";
import SatelliteSelectionClimate from "@/views/SatelliteSelectionClimate";
import SatelliteSelectionDisaster from "@/views/SatelliteSelectionDisaster";
import MissionSummaryFarming from "@/views/MissionSummaryFarming";
import MissionSummaryClimate from "@/views/MissionSummaryClimate";
import MissionSummaryDisaster from "@/views/MissionSummaryDisaster";
import LastScreen from "@/views/LastScreen";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'welcome-page',
    component: WelcomePage
  },
  {
    path: '/mission-selector',
    name: 'mission-selector',
    component: MissionSelector
  },
  {
    path: '/mission-briefing-farming',
    name: 'mission-briefing-farming',
    component: MissionBriefingFarming
  },
  {
    path: '/mission-briefing-deforestation',
    name: 'mission-briefing-deforestation',
    component: MissionBriefingClimate
  },
  {
    path: '/mission-briefing-disaster',
    name: 'mission-briefing-disaster',
    component: MissionBriefingDisaster
  },
  {
    path: '/mission-dashboard-farming',
    name: 'mission-dashboard-farming',
    component: MissionDashboardFarming
  },
  {
    path: '/mission-dashboard-deforestation',
    name: 'mission-dashboard-deforestation',
    component: MissionDashboardClimate
  },
  {
    path: '/mission-dashboard-disaster',
    name: 'mission-dashboard-disaster',
    component: MissionDashboardDisaster
  },
  {
    path: '/satellite-selection-farming',
    name: 'satellite-selection-farming',
    component: SatelliteSelectionFarming
  },
  {
    path: '/satellite-selection-deforestation',
    name: 'satellite-selection-deforestation',
    component: SatelliteSelectionClimate
  },
  {
    path: '/satellite-selection-disaster',
    name: 'satellite-selection-disaster',
    component: SatelliteSelectionDisaster
  },
  {
    path: '/imagery-payload-options',
    name: 'imagery-payload-options',
    component: ImageryPayloadOptions
  },
  {
    path: '/imagery-platform-options',
    name: 'imagery-platform-options',
    component: ImageryPlatformOptions
  },
  {
    path: '/imagery-orbit-options',
    name: 'imagery-orbit-options',
    component: ImageryOrbitOptions
  },
  {
    path: '/imagery-power-options',
    name: 'imagery-power-options',
    component: ImageryPowerOptions
  },
  {
    path: '/comms-payload-options',
    name: 'comms-payload-options',
    component: CommsPayloadOptions
  },
  {
    path: '/comms-platform-options',
    name: 'comms-platform-options',
    component: CommsPlatformOptions
  },
  {
    path: '/comms-orbit-options',
    name: 'comms-orbit-options',
    component: CommsOrbitOptions
  },
  {
    path: '/comms-power-options',
    name: 'comms-power-options',
    component: CommsPowerOptions
  },
  {
    path: '/nav-payload-options',
    name: 'nav-payload-options',
    component: NavPayloadOptions
  },
  {
    path: '/nav-platform-options',
    name: 'nav-platform-options',
    component: NavPlatformOptions
  },
  {
    path: '/nav-orbit-options',
    name: 'nav-orbit-options',
    component: NavOrbitOptions
  },
  {
    path: '/nav-power-options',
    name: 'nav-power-options',
    component: NavPowerOptions
  },
  {
    path: '/mission-summary-farming',
    name: 'mission-summary-farming',
    component: MissionSummaryFarming
  },
  {
    path: '/mission-summary-deforestation',
    name: 'mission-summary-deforestation',
    component: MissionSummaryClimate
  },
  {
    path: '/mission-summary-disaster',
    name: 'mission-summary-disaster',
    component: MissionSummaryDisaster
  },
  {
    path: '/final',
    name: 'final',
    component: LastScreen
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
