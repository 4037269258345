<template>
  <div
      @click="!locked ? $emit('click') : null"
      @dblclick="!locked ? $emit('dblclick') : null"
      class="selector"
      :class="{
        'active': isActive,
        'active-disaster': isActive && disaster
      }"
  >
    <div class="head" :class="{
      'head-lock': locked,
      disaster: disaster && !locked,
      'lock-disaster': locked && disaster,
    }">
      <h2 class="title">{{$t(text)}}</h2>
      <inline-svg :src="iconSrc"></inline-svg>
    </div>
    <div v-if="!locked" class="not-lock">
      <div class="img-wrap">
        <v-img class="img" cover height="159px" max-width="281px" :src=imgSrc></v-img>
      </div>
      <p class="desc">
        {{ desc }}
      </p>
      <p v-if="completed" class="completed">Mission Completed <v-icon color="#32E875">mdi-check</v-icon></p>
    </div>
    <div v-else class="locked">
      <p>{{$t('mission_locked')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionSelector",
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'farming',
    },
    iconSrc: {
      type: String,
      default: require('@/assets/imgs/icons/tree.svg')
    },
    imgSrc: {
      type: String,
      default: require('@/assets/imgs/satellite-view.jpg')
    },
    desc: {
      type: String,
      default: 'Help farmers and policy Makers to make better Farming decisions!'
    },
    disaster: {
      type: Boolean,
      default: false,
    }
  }

}
</script>

<style lang="scss" scoped>
.selector {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 16px;
  background: rgba(24, 24, 24, 0.85);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px);
  border-radius: 16px;
  min-height: 485px;
  cursor: pointer;

  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    svg {
      stroke: #FFFFFF;
    }
  }

  .not-lock {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 16px;
    .img-wrap {
      max-width: 100%;
      overflow: hidden;
      .img {
        border-radius: 4px;
        margin: 0 auto;
      }
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }
  .head-lock {
    .title {
      color: rgba(255, 255, 255, 0.65);
    }
    svg {
      stroke: rgba(255, 255, 255, 0.65);
    }
  }
  .disaster {
    svg {
      fill: #FFFFFF;
    }
  }
  .locked {
    text-transform: uppercase;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: auto;
  }
}

.active {
  background: #275238;
  border: 1px solid #32E875;
  backdrop-filter: blur(6px);
  .head {
    .title {
      color: #32E875;
    }
    svg {
      stroke: #32E875;
    }
  }
}
.lock-disaster {
  svg {
    fill: rgba(255, 255, 255, 0.65);
  }
}

.active-disaster {
  svg {
    fill: #32E875 !important;
  }
}

.completed {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: auto auto 40px;
  text-align: center;
  color: #32E875;
}
</style>