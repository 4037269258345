<template>
  <button
      class="btn"
      :class="{
         active
      }"
      @click="$emit('click')"
  >
    <span class="btn-text">{{ $t(btnText) }}</span>
    <inline-svg class="svg-inline" :src="iconSrc" :class="{
      'small-img': smallImg
    }"></inline-svg>
  </button>
</template>

<script>
export default {
  name: "ImageButton",
  props: {
    btnText: {
      type: String,
      default: ""
    },
    iconSrc: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    },
    smallImg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 24px 16px;
  gap: 24px;
  width: 100%;
  height: 100%;
  background: #181818;
  opacity: 0.85;
  border: 2px solid #848585;
  border-radius: 16px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  .btn-text {
    max-width: 180px;
  }
}
.active {
  background: #275238;
  border: 2px solid #32E875;
  color: #32E875;
}

.small-img {
  width: 54px;
  height: 54px;
}

.svg-inline {
  max-width: 140px;
  max-height: 140px;
}
</style>