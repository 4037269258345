<template>
  <div class="mission-briefing">
    <SideTexts />
    <div class="header">
      <h1 class="head-title title-fam text-uppercase">{{ $t('mission-brief') }}</h1>
    </div>
    <div class="content">
      <MissionBriefing
          v-if="climate"
          class="brief"
          @click="climateHandler"
          :img-src="require('@/assets/imgs/characters/climate.svg')"
          name="climate-s"
          desc="climate-desc"
      />
      <MissionBriefing
          v-if="un"
          @click="unHandler"
          @backClick="unBackHandler"
          :img-src="require('@/assets/imgs/characters/un.svg')"
          name="un"
          desc="un-desc"
          back
          class="brief"
      />
      <MissionBriefing
          v-if="gover"
          @click="goverHandler"
          @backClick="goverBackHandler"
          :img-src="require('@/assets/imgs/characters/government.svg')"
          name="gover"
          desc="gover-desc"
          back
          class="brief"
      />
      <MissionBriefing
          v-if="food"
          @click="foodHandler"
          @backClick="foodBackHandler"
          :img-src="require('@/assets/imgs/characters/producer.svg')"
          name="food"
          desc="food-desc"
          back
          class="brief"
      />

    </div>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MissionBriefing from "@/components/MissionBriefing";
export default {
  components: {
    MissionBriefing,
    SideTexts
  },
  data() {
    return {
      climate: true,
      un: false,
      gover: false,
      food: false
    }
  },
  methods: {
    climateHandler() {
      this.climate = false
      this.un = true
    },
    unHandler() {
      this.un = false
      this.gover = true
    },
    unBackHandler() {
      this.un = false
      this.climate = true
    },
    goverHandler() {
      this.gover = false
      this.food = true
    },
    goverBackHandler() {
      this.gover = false
      this.un = true
    },
    foodHandler() {
      localStorage.setItem('climateBrief', 'true')
      this.$router.push('mission-dashboard-deforestation')
    },
    foodBackHandler() {
      this.food = false
      this.gover = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/mission-briefing.scss";
</style>