<template>
  <div class="mission-briefing">
    <SideTexts />
    <div class="header">
      <h1 class="head-title title-fam text-uppercase">{{ $t('mission-brief') }}</h1>
    </div>
    <div class="content">
      <MissionBriefing
          v-if="emergency"
          class="brief"
          @click="emergencyHandler"
          :img-src="require('@/assets/imgs/characters/emergency.svg')"
          name="emergency"
          desc="emergency-desc"
      />
      <MissionBriefing
          v-if="aid"
          @click="aidHandler"
          @backClick="aidBackHandler"
          :img-src="require('@/assets/imgs/characters/aid.svg')"
          name="aid"
          desc="aid-desc"
          back
          class="brief"
      />
      <MissionBriefing
          v-if="local"
          @click="localHandler"
          @backClick="localBackHandler"
          :img-src="require('@/assets/imgs/characters/government.svg')"
          name="lc"
          desc="lc-desc"
          back
          class="brief"
      />
      <MissionBriefing
          v-if="un"
          @click="unHandler"
          @backClick="unBackHandler"
          :img-src="require('@/assets/imgs/characters/un2.svg')"
          name="un2"
          desc="un2-desc"
          back
          class="brief"
      />

    </div>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MissionBriefing from "@/components/MissionBriefing";
export default {
  components: {
    MissionBriefing,
    SideTexts
  },
  data() {
    return {
      emergency: true,
      aid: false,
      local: false,
      un: false
    }
  },
  methods: {
    emergencyHandler() {
      this.emergency = false
      this.aid = true
    },
    aidHandler() {
      this.aid = false
      this.local = true
    },
    aidBackHandler() {
      this.aid = false
      this.emergency = true
    },
    localHandler() {
      this.local = false
      this.un = true
    },
    localBackHandler() {
      this.local = false
      this.aid = true
    },
    unHandler() {
      localStorage.setItem('disasterBrief', 'true')
      this.$router.push('mission-dashboard-disaster')
    },
    unBackHandler() {
      this.un = false
      this.local = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/mission-briefing.scss";
</style>