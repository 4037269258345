<template>
  <div class="imagery-wrap">
    <SideTexts />
    <div class="header">
      <button class="back-btn" @click="backHandler">
        <inline-svg :src="require('@/assets/imgs/icons/arrow.svg')"></inline-svg>
      </button>
      <h1 class="head-title title-fam">{{$t('i-orbit-title')}}</h1>
    </div>
    <v-row>
      <v-col cols="4">
        <div class="optical-wrap">
          <div class="optical-head flex-start">
            <h3 class="title">{{ leftSideContent?.name }}</h3>
            <div class="">
              <p class="mb-0 text-end">
                <inline-svg :src="require('@/assets/imgs/icons/satellite-color.svg')"></inline-svg>
              </p>
              <p class="sub">Platform</p>
            </div>
          </div>
          <inline-svg
              :src="geoOrbit ? require('@/assets/imgs/orbit-geostationary.svg') : require('@/assets/imgs/orbit.svg')">
          </inline-svg>
          <p class="opt-desc">
            {{ leftSideContent?.description }}
          </p>
          <div class="points">
            <p class="opt-point">
              <span>{{ $t('cost') }}</span>
              <span>£ {{ leftSideContent?.cost }}m</span>
            </p>
          </div>
          <MainButton
              @click="addHandler"
              class="add-btn"
              text="add"
          />
          <MainButton
              @click="removeHandler"
              class="add-btn"
              text="remove"
          />
        </div>
      </v-col>
      <v-col cols="8" class="d-flex align-center">
        <v-row>
          <v-col cols="6">
            <ImageButton
                @click="lowEOrbit = !lowEOrbit"
                :active="lowEOrbit"
                class="img-btn-wrap"
                btn-text="lowEOrbit"
                :icon-src="require('@/assets/imgs/orbit.svg')"
            />
          </v-col>
          <v-col cols="6">
            <ImageButton
                @click="geoOrbit = !geoOrbit"
                :active="geoOrbit"
                class="img-btn-wrap"
                btn-text="geoOrbit"
                :icon-src="require('@/assets/imgs/orbit-geostationary.svg')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideTexts from "@/components/SideTexts";
import MainButton from "@/components/MainButton";
import ImageButton from "@/components/ImageButton";

export default {
  components: {
    MainButton,
    ImageButton,
    SideTexts
  },
  data() {
    return {
      lowEOrbit: false,
      geoOrbit: false,
      farming: false,
      climate: false,
      disaster: false,
      items: [],
    }
  },
  mounted() {
    if(this.$route.query.farming) {
      this.farming = true
      this.checkIfAlreadySelected('farmingImageryOrbit')
    }
    if(this.$route.query.climate) {
      this.climate = true
      this.checkIfAlreadySelected('climateImageryOrbit')
    }
    if(this.$route.query.disaster) {
      this.disaster = true
      this.checkIfAlreadySelected('disasterImageryOrbit')
    }
    this.items = JSON.parse(localStorage.getItem('allData')).orbit_items
  },
  computed: {
    leftSideContent() {
      if (this.geoOrbit) {
        return this.items.find(i => i.item_id === 'orbit:geo')
      } else {
        return this.items.find(i => i.item_id === 'orbit:leo')
      }
    }
  },
  watch: {
    lowEOrbit(v) {
      if (v) {
        this.geoOrbit = false
      }
    },
    geoOrbit(v) {
      if (v) {
        this.lowEOrbit = false
      }
    },
  },
  methods: {
    addHandler() {
      if(this.farming) {
        this.$router.push(`satellite-selection-farming`)
        localStorage.setItem('farmingImageryOrbit', JSON.stringify(this.leftSideContent))
      }
      if(this.climate) {
        this.$router.push(`satellite-selection-deforestation`)
        localStorage.setItem('climateImageryOrbit', JSON.stringify(this.leftSideContent))
      }
      if(this.disaster) {
        this.$router.push(`satellite-selection-disaster`)
        localStorage.setItem('disasterImageryOrbit', JSON.stringify(this.leftSideContent))
      }
    },
    backHandler() {
      if(this.farming) {
        this.$router.push('satellite-selection-farming')
      }
      if(this.climate) {
        this.$router.push('satellite-selection-deforestation')
      }
      if(this.disaster) {
        this.$router.push('satellite-selection-disaster')
      }
    },
    checkIfAlreadySelected(name) {
      if(JSON.parse(localStorage.getItem(name))){
        const id = JSON.parse(localStorage.getItem(name)).item_id
        return id === 'orbit:leo' ? this.lowEOrbit = true
            : this.geoOrbit = true
      }
    },
    removeHandler() {
      if(this.farming) {
        localStorage.removeItem('farmingImageryOrbit')
      }
      if(this.climate) {
        localStorage.removeItem('climateImageryOrbit')
      }
      if(this.disaster) {
        localStorage.removeItem('disasterImageryOrbit')
      }
      this.lowEOrbit= false
      this.geoOrbit = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/imagery-payload.scss";
</style>