import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {i18n} from "@/plugins/i18n";
import InlineSvg from 'vue-inline-svg';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


import "@/plugins/mixins";

import '@/assets/scss/global.scss'
Vue.component('inline-svg', InlineSvg);
Vue.config.productionTip = false
Vue.use(Toast)


new Vue({
  router,
  store,
  vuetify,
  i18n,
  Toast,
  render: h => h(App)
}).$mount('#app')
