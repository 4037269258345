<template>
  <div class="loading-wrap">
    <h1 v-if="homePage" class="main-title title-fam">SATELLITE ARCHITECT</h1>
    <div v-if="launch" class="loader">
      <h1 v-if="!stfc" class="title-fam">Launching Mission...</h1>
      <h1 v-else class="title-fam">Connecting to STFC Ground Segment...</h1>

    </div>

    <inline-svg class="img-orbit" :src="require('@/assets/imgs/orbit.svg')"></inline-svg>
    <div v-if="hub" class="loader">
      <h2 class="title-fam">Connecting to space hub...</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    homePage: {
      type: Boolean,
      default: false,
    },
    launch: {
      type: Boolean,
      default: false,
    },
    hub: {
      type: Boolean,
      default: true,
    },
    flexPosition:{
      type: String,
      default: 'space-between'
    },
    animationSteps: {
      type: Number,
      default: 26,
    },
    textWidth: {
      type: String,
      default: '396.26px',
    },
    stfc: {
      type: Boolean,
      default: false
    }
  },
}

</script>

<style lang="scss" scoped>
.loading-wrap {
  height: 100%;
  padding: 40px 80px;
  overflow: hidden;
  /* Center and scale the image nicely */
  background: url('~@/assets/imgs/default-bg.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: v-bind(flexPosition);
  align-items: center;
  flex-direction: column;
  gap: 100px;

  .img-orbit {
    width: 250px;
    height: 250px;
    animation: rotation 5s infinite linear;
    @media (min-width: 2000px) {
      width: 400px;
      height: 400px;
    }
  }

  .main-title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 50px;
    line-height: 72px;
  }

  .loader {
    text-align: center;
    color: #FFFFFF;
    position: relative;
    h1 {
      position: relative;
      animation: typing 8s steps(v-bind(animationSteps)) infinite;
      font-weight: 700;
      font-size: 50px;
      line-height: 72px;
      overflow: hidden;
      white-space: nowrap;
    }
    h2 {
      position: relative;
      animation: typing 8s steps(v-bind(animationSteps)) infinite;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      overflow: hidden;
      white-space: nowrap;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      background: #FFFFFF;
      animation: blinkCursor 0.8s steps(3) infinite;
    }
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes typing {
  0%,90%,100% {
    width: 0;
  }
  30%,60% {
    width: v-bind(textWidth);
  }
}
@keyframes blinkCursor {
  0%,75% {
    opacity: 1;
  }
  76%,100% {
    opacity: 0;
  }
}
</style>