var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector",class:{
      'active': _vm.isActive,
      'active-disaster': _vm.isActive && _vm.disaster
    },on:{"click":function($event){!_vm.locked ? _vm.$emit('click') : null},"dblclick":function($event){!_vm.locked ? _vm.$emit('dblclick') : null}}},[_c('div',{staticClass:"head",class:{
    'head-lock': _vm.locked,
    disaster: _vm.disaster && !_vm.locked,
    'lock-disaster': _vm.locked && _vm.disaster,
  }},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.text)))]),_c('inline-svg',{attrs:{"src":_vm.iconSrc}})],1),(!_vm.locked)?_c('div',{staticClass:"not-lock"},[_c('div',{staticClass:"img-wrap"},[_c('v-img',{staticClass:"img",attrs:{"cover":"","height":"159px","max-width":"281px","src":_vm.imgSrc}})],1),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.desc)+" ")]),(_vm.completed)?_c('p',{staticClass:"completed"},[_vm._v("Mission Completed "),_c('v-icon',{attrs:{"color":"#32E875"}},[_vm._v("mdi-check")])],1):_vm._e()]):_c('div',{staticClass:"locked"},[_c('p',[_vm._v(_vm._s(_vm.$t('mission_locked')))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }